import React from 'react';

import Helmet from '@component/Helmet';

import CommonQuestions from './components/CommonQuestions';
import Hero from './components/Hero';
import Withdrawals from './components/Withdrawals';

const PricingTable: React.FC = () => {
  return (
    <>
      <Helmet
        title="Pricing"
        description="Explore ZinariPay's competitive pricing for secure and scalable cryptocurrency payment solutions. Understand our fee structure and discover how our transparent pricing can benefit your business."
        keywords="zinari, zinari coin, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, ZinariPay pricing, cryptocurrency payment fees, blockchain transaction costs, fintech pricing, transparent pricing"
      />
      <Hero />
      <Withdrawals />
      <CommonQuestions />
    </>
  );
};

export default PricingTable;
