import { Box } from '@mui/material';
import React from 'react';

import Why from '@component/Why';
import CallToAction from '@component/CallToAction';
import Developer from '@component/DeveloperBack';

import Hero from './components/Hero';
import Features from './components/Features';
import PayLink from './components/PayLink';
import GetStarted from './components/GetStarted';
import Pricing from './components/Pricing';

const Landing = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Hero />
      <PayLink />
      <Features />
      <Why />
      <GetStarted />
      <Pricing />
      <Developer />
      <CallToAction />
    </Box>
  );
};

export default Landing;
