import React from 'react';

import Helmet from '@component/Helmet';

import Content from './Content';

const PayLink = () => (
  <>
    <Helmet
      title="PayLink"
      description="Zinari PayLink allows businesses to easily accept USDT and USDC payments without any coding or technical expertise. Sign up, complete KYC, and start sending payment links via email."
      keywords="zinari, zinari coin, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, ZinariPay, PayLink, crypto payments, USDT, USDC, no coding, non-developers, KYC, secure payments, cryptocurrency payment gateway"
    />
    <Content />
  </>
);

export default PayLink;
