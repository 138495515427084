import { Box } from '@mui/material';
import React from 'react';

import Why from '@component/Why';
import CallToAction from '@component/CallToAction';
import Helmet from '@component/Helmet';

import Hero from './components/Hero';
import Safe from './components/Safe';
import Scan from './components/Scan';

const WhyZinari = () => {
  return (
    <Box pt={{ md: 19, xs: 12 }}>
      <Helmet
        title="Why ZinariPay?"
        description="Discover why ZinariPay is the ideal crypto payment gateway for businesses. Secure, fast, and tailored for African businesses with seamless cryptocurrency payment integration."
        keywords="zinari, zinari coin, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, Why ZinariPay, crypto payment gateway, cryptocurrency payments, secure payments, African businesses, blockchain payments, fast transactions, ZinariPay benefits"
      />
      <Hero />
      <Safe />
      <Scan />
      <Why />
      <CallToAction />
    </Box>
  );
};

export default WhyZinari;
