import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Info } from '@mui/icons-material';

import { CONSOLE_URL } from '@variable';

const PromoStrip = () => (
  <Stack
    direction="row"
    gap={3}
    sx={{
      py: 2,
      color: 'common.white',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      background:
        'linear-gradient(90deg, #35624F 0%, #469674 46.92%, #2CFDA3 100%)',
    }}
  >
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Info />
    </Box>
    <Typography variant="body1" fontSize="20px">
      New users get the first 3 months with NO processing fees.{' '}
      <Link
        href={CONSOLE_URL}
        target={'_blank'}
        rel="noreferrer"
        color="primary.900"
        fontWeight={700}
      >
        Get Started Now!
      </Link>{' '}
      {`💸🤑`}
    </Typography>
  </Stack>
);

export default PromoStrip;
